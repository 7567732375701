import '../../../rich-content/components/rich-content-editor/rich-content-editor.global.scss';
import './app-root.scss';

import React from 'react';
import loadable from '@loadable/component';
import {
  ImgProvider,
  RENDER_IMAGE,
  RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY,
} from '@wix/communities-image-lib';
import { I18nextProvider } from '@wix/yoshi-flow-editor';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_OOI_IN_EDITOR } from '@wix/communities-blog-experiments';

import BlogPushNotifications from '../../../common/components/blog-push-notifications';
import ComponentsProvider from '../../../common/components/components-provider';
import PermissionsProvider from '../../../common/components/permissions-provider';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { connect } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import WindowResizeListener from '../../../common/components/window-resize-listener';
import {
  getPinterestEnabled,
  getIsCreatedWithResponsiveEditor,
} from '../../../common/selectors/app-settings-selectors';
import { buildPlaceholderSrc } from '../../../common/services/build-placeholder-src';
import { initI18n } from '../../../common/services/init-i18n';
import { isWebpSupported } from '../../../common/services/is-webp-supported';
import pinterest from '../../../common/services/pinterest';
import {
  getLanguage,
  isSeo,
  getIsMobile,
  isEditor,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import PostListItem from '../../components/post-list-item';
import SideBySideMobile from '../../components/post-list-item/mobile/side-by-side';
import TextOnImageMobile from '../../components/post-list-item/mobile/text-on-image';
import PostListMasonry from '../../components/post-list/post-list-masonry';
import PostListMobile from '../../components/post-list/post-list-mobile';
import PostListSimple from '../../components/post-list/post-list-simple';
import Router from '../router';

const LazyPost = loadable(() =>
  import(
    /* webpackChunkName: "full-post-layout" */ '../../../common/components/post'
  ),
);
const LazyPostListProGallery = loadable(() =>
  import(
    /* webpackChunkName: "post-list-pro-gallery" */ '../../../common/components/post-list-pro-gallery'
  ),
);

class AppRoot extends React.Component {
  constructor(props) {
    super(props);
    const { language, isMobile, isPinterestEnabled } = this.props;
    pinterest.init({ language, isMobile, isPinterestEnabled });
  }

  componentDidMount() {
    if (this.props.shouldSetAutoHeight) {
      document.body.style.height = 'auto';
    }
  }

  componentWillUnmount() {
    pinterest.removeEventListener();
  }

  getI18nConfig = () => {
    const { language, translations } = this.props;

    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { addResizeListener, isSeo, isMobile } = this.props;
    const i18n = this.getI18nConfig();

    return (
      <ResponsiveListener>
        <PermissionsProvider>
          <ComponentsProvider
            PostListProGallery={LazyPostListProGallery}
            PostListSimple={PostListSimple}
            PostListMasonry={PostListMasonry}
            PostListMobile={PostListMobile}
            PostListItem={PostListItem}
            Post={LazyPost}
            SideBySideMobile={SideBySideMobile}
            TextOnImageMobile={TextOnImageMobile}
          >
            <I18nextProvider i18n={i18n}>
              <ImgProvider
                renderMode={
                  isSeo ? RENDER_IMAGE : RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY
                }
                buildPlaceholderSrc={isMobile ? buildPlaceholderSrc : undefined}
                {...(isWebpSupported() ? { format: 'webp' } : {})}
              >
                <>
                  <Router />
                  {addResizeListener && <WindowResizeListener />}
                  <BlogPushNotifications />
                </>
              </ImgProvider>
            </I18nextProvider>
          </ComponentsProvider>
        </PermissionsProvider>
      </ResponsiveListener>
    );
  }
}

const mapRuntimeToProps = (state) => {
  const addResizeListener =
    isEditor(state) &&
    !isExperimentEnabled(state, EXPERIMENT_OOI_IN_EDITOR) &&
    !getIsCreatedWithResponsiveEditor(state);
  const shouldSetAutoHeight =
    isEditor(state) && getIsCreatedWithResponsiveEditor(state);
  return {
    language: getLanguage(state),
    translations: getTranslations(state),
    isSeo: isSeo(state),
    isMobile: getIsMobile(state),
    isPinterestEnabled: getPinterestEnabled(state),
    addResizeListener,
    shouldSetAutoHeight,
  };
};

export default withReduxStore(connect(mapRuntimeToProps)(AppRoot));
