import React, { Component } from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import {
  SECTION_CATEGORY,
  isLayoutProGallery,
  getAppConfig,
  getCategoryUrl,
  createPageUrl,
  getCategoryBySlug,
  resolveId,
  FEED_LAYOUT_PARAMS,
  resolveLegacyId,
} from '@wix/communities-blog-client-common';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import withComponents from '../../../common/components/components-provider/with-components';
import { EmptyLayout } from '../../../common/components/layout';
import Page from '../../../common/components/page';
import { connect } from '../../../common/components/runtime-context';

import Pagination from '../../../common/containers/pagination';
import withAuth from '../../../common/hoc/with-auth';
import withLayoutProps from '../../../common/hoc/with-layout-props';
import withPermissions from '../../../common/hoc/with-permissions';
import withTranslate from '../../../common/hoc/with-translate';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import {
  getCanSeeMoreButton,
  getIsCategoryHeaderEnabled,
} from '../../../common/selectors/app-settings-selectors';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import {
  getSortedPostsByCategoryId,
  getPostsByCategoryIdAndPage,
} from '../../../common/selectors/post-selectors';
import { handleNavigation } from '../../../common/services/navigation';
import { resolveCategorySlug } from '../../../common/services/slug';
import {
  getIsMobile,
  isSite as getIsSite,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getIsCategoryLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsCategoryLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import CategoryHeader from '../../components/category-header';
import PostList from '../../components/post-list';
import withPaginationSettings from '../../hoc/with-pagination-settings';
import NoPosts from '../no-posts';

const Layout = EmptyLayout;

export class CategoryPage extends Component {
  state = {
    isAnimated: !this.props.allPosts.length,
  };

  componentWillUnmount() {
    const { category } = this.props;

    this.props.setIsLoaded('category', resolveId(category), false);
  }

  componentDidUpdate({ isAuthenticated, isBlocked, pageSize, excludeContent }) {
    const { category } = this.props;
    if (
      this.props.isAuthenticated !== isAuthenticated ||
      this.props.isBlocked !== isBlocked ||
      this.props.pageSize !== pageSize ||
      (excludeContent && !this.props.excludeContent)
    ) {
      this.fetchPosts(resolveId(category));
    }
  }

  fetchPosts(categoryId, page = this.props.page) {
    if (this.props.isBlocked) {
      return;
    }

    this.props.fetchCategoryPosts({
      categoryId,
      page,
      excludeContent: this.props.excludeContent,
    });
  }

  loadMore = (page) => this.fetchPosts(resolveId(this.props.category), page);
  loadRest = () =>
    this.fetchPosts(resolveId(this.props.category), this.props.page);

  renderPosts() {
    const {
      currentPagePosts,
      allPosts,
      entityCount,
      category,
      isCategoryLoading,
      isCategoryLoaded,
      showCreatePostAction,
      page,
      layoutType,
      showPagination,
      PostListProGallery,
      canSeeMoreButton,
      pageSize,
    } = this.props;
    const hasPosts = allPosts.length;

    if (!hasPosts && isCategoryLoaded) {
      return <NoPosts category={category} />;
    }

    if (isLayoutProGallery(layoutType)) {
      return (
        <PostListProGallery
          pageStart={page + 1}
          entityCount={entityCount}
          loadMore={showPagination ? undefined : this.loadMore}
          allPosts={showPagination ? currentPagePosts : allPosts}
          currentPagePosts={currentPagePosts}
          isLoading={isCategoryLoading}
          showCreatePostAction={showCreatePostAction}
          canSeeMoreButton={canSeeMoreButton}
          layoutOptions={this.props.layoutOptions}
          section={SECTION_CATEGORY}
        />
      );
    }

    return (
      <PostList
        pageStart={page + 1}
        layoutType={layoutType}
        entityCount={entityCount}
        page={page}
        pageSize={pageSize}
        loadRest={showPagination ? this.loadRest : undefined}
        loadMore={showPagination ? undefined : this.loadMore}
        allPosts={showPagination ? currentPagePosts : allPosts}
        currentPagePosts={currentPagePosts}
        isLoading={isCategoryLoading}
        category={category}
        isLoaded={isCategoryLoaded}
        showCreatePostAction={showCreatePostAction}
      />
    );
  }

  createPageUrl = (page) => createPageUrl(page, this.props.categoryUrl);

  renderPagination() {
    const {
      entityCount,
      pageSize,
      page,
      isSite,
      categoryPath,
      showPagination,
    } = this.props;
    return (
      <div
        data-hook="category-pagination-container"
        style={{ display: showPagination ? 'block' : 'none' }}
      >
        <Pagination
          page={page}
          pageSize={pageSize}
          entityCount={entityCount}
          createPageUrl={this.createPageUrl}
          handleNavigation={handleNavigation(categoryPath, isSite)}
          showPagination={showPagination}
        />
      </div>
    );
  }

  render() {
    const {
      category,
      isCategoryLoading,
      isCategoryLoaded,
      allPosts,
      isCategoryHeaderEnabled,
    } = this.props;

    const isLoading = isCategoryLoading && !isCategoryLoaded;

    return (
      <Page noSpacing>
        <Layout>
          {isCategoryHeaderEnabled && <CategoryHeader category={category} />}
          <AnimatedLoader
            isLoading={isLoading && !allPosts.length}
            isAnimated={this.state.isAnimated}
          >
            {this.renderPosts()}
          </AnimatedLoader>
          {!isLoading && this.renderPagination()}
          {isCategoryLoaded && <AppLoaded />}
        </Layout>
      </Page>
    );
  }
}

CategoryPage.propTypes = {
  categoryUrl: PropTypes.string.isRequired,
  categoryPath: PropTypes.string.isRequired,
  fetchCategoryPosts: PropTypes.func.isRequired,
  categoryId: PropTypes.string, // no id when creating new category in editor
  setIsLoaded: PropTypes.func.isRequired,
  allPosts: PropTypes.array,
  currentPagePosts: PropTypes.array,
  category: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  params: PropTypes.object,
  t: PropTypes.func,
  newPostId: PropTypes.string,
  isCategoryLoading: PropTypes.bool,
  isCategoryLoaded: PropTypes.bool,
  isSite: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  layoutType: PropTypes.number.isRequired,
  showHeader: PropTypes.bool,
  isCategoryHeaderEnabled: PropTypes.bool,
  excludeContent: PropTypes.bool,
  pageSize: PropTypes.number,
  entityCount: PropTypes.number,
  showPagination: PropTypes.bool,
  useCategoryMenuLabelForMetadataTitle: PropTypes.bool,
  isMoveCreatePostButtonEnabled: PropTypes.bool,
  PostListProGallery: PropTypes.elementType.isRequired,
  canSeeMoreButton: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const categorySlug = resolveCategorySlug(ownProps.params);
  const category = getCategoryBySlug(state, categorySlug) || {};
  const categoryId = resolveId(category);
  const legacyCategoryId = resolveLegacyId(category);

  const currentPagePosts = legacyCategoryId
    ? getPostsByCategoryIdAndPage(state, legacyCategoryId, ownProps.page)
    : [];
  const { categoryPath, useCategoryMenuLabelForMetadataTitle } =
    getAppConfig(state);
  const sectionUrl = getSectionUrl(state);
  const categoryUrl = getCategoryUrl(
    sectionUrl,
    categoryPath,
    categorySlug,
    true,
  );

  const showCreatePostAction =
    getIsMobile(state) && isCreatePostButtonVisible(state, ownProps.canSee);

  const {
    sliderShowArrows,
    sliderArrowsSize,
    sliderAutoSlide,
    sliderPauseTime,
    sliderArrowsPosition,
    sliderArrowsColor,
    sliderLoop,
  } = FEED_LAYOUT_PARAMS;

  return {
    categoryUrl,
    categoryPath: `/${categoryPath}/${categorySlug}`,
    category,
    allPosts: legacyCategoryId
      ? getSortedPostsByCategoryId(state, legacyCategoryId)
      : [],
    currentPagePosts,
    newPostId: state.newPostMessage,
    categoryId,
    isCategoryLoading: getIsCategoryLoading(state, categoryId),
    isCategoryLoaded: getIsCategoryLoaded(state, categoryId),
    isSite: getIsSite(state),
    showCreatePostAction,
    excludeContent: isExcludePostContentSupported(state),
    useCategoryMenuLabelForMetadataTitle,
    setIsLoaded: actions.setIsLoaded,
    fetchCategoryPosts: actions.fetchCategoryPostsPromisified,
    isCategoryHeaderEnabled: getIsCategoryHeaderEnabled(state),
    canSeeMoreButton: getCanSeeMoreButton(state, ownProps.canSee),
    layoutOptions: {
      showArrows: getAppSettingsValue({
        state,
        key: sliderShowArrows.appSettingsPath,
        fallback: sliderShowArrows.defaultValue,
      }),
      arrowsSize: getAppSettingsValue({
        state,
        key: sliderArrowsSize.appSettingsPath,
        fallback: sliderArrowsSize.defaultValue,
      }),
      autoSlide: getAppSettingsValue({
        state,
        key: sliderAutoSlide.appSettingsPath,
        fallback: sliderAutoSlide.defaultValue,
      }),
      pauseTime: getAppSettingsValue({
        state,
        key: sliderPauseTime.appSettingsPath,
        fallback: sliderPauseTime.defaultValue,
      }),
      arrowsPosition: getAppSettingsValue({
        state,
        key: sliderArrowsPosition.appSettingsPath,
        fallback: sliderArrowsPosition.defaultValue,
      }),
      arrowsColor: getAppSettingsValue({
        state,
        key: sliderArrowsColor.appSettingsPath,
        fallback: sliderArrowsColor.defaultValue,
      }),
      loop: getAppSettingsValue({
        state,
        key: sliderLoop.appSettingsPath,
        fallback: sliderLoop.defaultValue,
      }),
    },
  };
};

export default flowRight(
  withComponents,
  withPermissions,
  withPaginationSettings(SECTION_CATEGORY),
  withLayoutProps(),
  withAuth,
  withTranslate,
  connect(mapRuntimeToProps),
)(CategoryPage);
