import { urijs } from '../urijs';
import { getOuterUrl } from './get-outer-url';
export var numberToPage = function (page, containsTrailingSlash) {
    if (containsTrailingSlash === void 0) { containsTrailingSlash = false; }
    return "".concat(page > 1 ? "/page/".concat(page) : '').concat(containsTrailingSlash ? '/' : '');
};
export var createPageUrl = function (page, sectionUrl) {
    var containsTrailingSlash = new urijs(sectionUrl || '')
        .pathname()
        .endsWith('/');
    return getOuterUrl(numberToPage(page, containsTrailingSlash), sectionUrl);
};
export var buildPaginationRequestParams = function (page, pageSize, cursor) {
    if (page !== undefined) {
        if (cursor && page > 1) {
            return {
                cursor: cursor,
                size: pageSize,
            };
        }
        return {
            offset: (page - 1) * pageSize,
            size: pageSize,
        };
    }
    return {};
};
export var getLastPage = function (entityCount, pageSize) {
    if (pageSize === void 0) { pageSize = 1; }
    return Math.ceil(entityCount / pageSize);
};
